import { Inject, Injectable, PLATFORM_ID, APP_ID, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router,  ActivatedRoute } from '@angular/router';

import { Request, Response } from 'express';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';

// import { API_URL, TRANSLATE_PATH_URL } from '../../config.json';
import { environment } from 'src/environments/environment';

import { isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import { SettingsContainer } from '../types/settings';
import { ApiResponse } from '../types/apiResponse';

import * as Cookies from 'js-cookie';
import { User } from '../types/user';
import { Config, Locales, Region, Settings } from '../types/settings';
import { UTM } from '../types/utm';
import { userInfo } from 'os';
import { env } from 'process';


@Injectable({ providedIn: 'root' })
export class SettingsService {
  private settingsUrl = `${environment.API_URL}/v3/auth/platform_settings?d_type=web&logged_in=0&locale=`; // URL to web api
  public loaderSource = new BehaviorSubject(true);

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  public static userInfo: User;
  public static configInfo: Config;
  public static settingsInfo: Settings;
  public static utm: UTM;
  langKeys;
  localesdata;
  planInfo;
  trialDate;
  public static selectedLocale: string;
  locale;
  isMobile;
  isTablet;
  isDesktopDevice;
  webpFlag;
  browserRegion;
  regionTv;
  s3_epg_path = "";
  display_epg_time_format:12;
  imagePath;
  epg_series_placeholder;
  epg_video_placeholder;
  userSettingsData;
  platformSettings: ApiResponse<SettingsContainer>;
  freeTrialDays;

  constructor(private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object,
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    @Inject(APP_ID) private appId: string, private injector: Injector) {
      if (isPlatformServer(this.platformId)) {
      const request = this.injector.get(REQUEST);
      this.browserRegion = request.path.split("/")[1];
      //request.route
      this.locale = request.query.locale;
      //this.locale = 'en-US';
    } else if (isPlatformBrowser(this.platformId)) {
      this.locale = localStorage.getItem('locale');
      router.events.subscribe((val) => {
        if(location.path() != ''){
          const request = location.path();
          this.browserRegion = request.split("/")[1];
        } 
      });
    }
    if (isPlatformBrowser(this.platformId)) {
      this.checkWebP(support => {
        if(support) {
            this.webpFlag = 1;
        }else{
            this.webpFlag = 2;
        }
      })
    }
    
  }

  /** GET settings from the server */
  async getSettings(): Promise<SettingsContainer> {
    try {
      if(!this.locale) {
        this.locale = 'en-US';
      }
      this.platformSettings = await this.http
        .get<ApiResponse<SettingsContainer>>(this.settingsUrl+this.locale)
        .toPromise();
      if (this.platformSettings.data) {
        //this.loaderSource.next(false);
        const userSettingsApiRes = await this.getUserSettings();
        SettingsService.userInfo = userSettingsApiRes.user;
        SettingsService.configInfo = userSettingsApiRes.configuration;
        this.planInfo = this.platformSettings?.data?.plan[userSettingsApiRes?.configuration?.region?.region_code]?.amount;
        this.freeTrialDays = this.platformSettings?.data?.plan[userSettingsApiRes?.configuration?.region?.region_code]?.free_trial_days;
        if ( this.platformSettings?.data?.plan[userSettingsApiRes?.configuration?.region?.region_code]?.free_trial_days > 0) this.trialDate = this.platformSettings?.data?.plan[userSettingsApiRes?.configuration?.region?.region_code]?.free_trial_text;
        SettingsService.settingsInfo = this.platformSettings.data.settings;
        this.imagePath = SettingsService.settingsInfo.image_base_path;
        environment.IMAGE_PATH_URL = this.platformSettings.data.settings.image_base_path;

        this.s3_epg_path  = SettingsService.settingsInfo['s3_epg_path'];
        this.epg_series_placeholder = SettingsService.settingsInfo['epg_series_placeholder'];
        this.epg_video_placeholder = SettingsService.settingsInfo['epg_video_placeholder'];
        //if(SettingsService.selectedLocale) {
        if (userSettingsApiRes) SettingsService.selectedLocale = userSettingsApiRes?.configuration?.region['locale'];

      }

      //}

      return this.platformSettings.data;
    } catch (err) {
      this.loaderSource.next(false);
      console.error(err);
      return null;
    }
  }


  getUserSettings:any = async() => {
    try {
      if(!this.locale) {
        this.locale = 'en-US';
      }
      let userSettingsUrl = environment.API_URL + '/' + environment.v3_version + `auth/user_settings?d_type=web&logged_in=0&locale=${this.locale}`;
      const reqObj = {
        d_type: 'web',
        logged_in: 0,
        locale: this.locale
      }
      const apiResponse: any = await this.http
      .post<ApiResponse<SettingsContainer>>(userSettingsUrl, reqObj)
      .toPromise();
      this.userSettingsData = apiResponse?.data;
      return apiResponse?.data;
    } catch (err) {
      this.loaderSource.next(false);
      console.error(err);
      return null;
    }
  }

  /** Get Locale and Region Data */
  public getRegion(): Array<Region> {
    let region = SettingsService.configInfo.region;
    if (region) {
      return region;
    }
    return null;
  }

  public getCountryCode(): any {
    let country_code = SettingsService.configInfo.country_code;
    if (country_code) {
      return country_code;
    }
    return '';
  }

  public getBrowserRegion(): any {
    let region = SettingsService.configInfo.region;
    if(this.browserRegion == 'tv-guide') this.browserRegion = this.regionTv;
    if (region) {
      if (this.browserRegion) {
        if (this.browserRegion != region['region_code']) {
            let browser = [];
            browser["region_code"] = this.browserRegion;
            return browser;
        }
      }
      return region;
    }
    return null;
  }

  public getLocale(): Array<Locales> {
    let locales = this.platformSettings?.data?.configuration?.locales;
    if (locales) {
      this.localesdata = locales;
      return locales;
    }
    return null;
  }

  public async getLocaleData(locale) {
    let locales = this.platformSettings?.data?.configuration?.locales;
    let json_path_obj = locales.find(loc => {
      return loc.lang_code === locale;
    });
    this.langKeys = await this.http.get(json_path_obj.path).toPromise()
  }

  public getSelectedLocale() {
    return SettingsService.selectedLocale;
  }

  public async getLocaleStaticData(locale) {
    try{
      let locales = this.platformSettings?.data?.configuration?.locales;
      let json_path_obj = locales.find(loc => {
        return loc.lang_code === locale;
      });
      //this.langKeys = await this.http.get(environment.TRANSLATE_PATH_URL + locale + '.json').toPromise()
      this.langKeys = await this.http.get(json_path_obj.path).toPromise(); 
    }catch(error){
      console.error(error);
    }
  }

  // post api call for checking email from getstarted widget
  async checkEmailAvailable(email){
    try{
      let reg = this.route.snapshot.firstChild.url[0].path;
      let countryCode = SettingsService.configInfo.country_code;
      let local;
      this.route.queryParams.subscribe(params => {
        local = params['locale'];
      });
      const requestBody = {
        d_type:'web',
        country_code:countryCode,
        locale:local,
        region:reg,
        email: email,
      };
      const collectionUrl = `${environment.API_URL}/v2/auth/check/user`;
      const apiResponse = await this.http.post<ApiResponse<any>>(collectionUrl , requestBody ).toPromise();
      return apiResponse.data;
    }catch(err){
      console.error(err);
      return null;
    }
  }

  getImage(image_name, carousel_type_mobile, carousel_type_web) {
    //const fileExt = image_name.split('.').pop();
    if (isPlatformBrowser(this.platformId)) {
      const filename = image_name.split('.').slice(0, -1).join('.');
      let fileExt;
      if(this.webpFlag == 1){
        fileExt = "webp";
      } else {
        fileExt = image_name.split('.').pop();
      }
      var newFilename = filename+'_'+carousel_type_web+'.'+fileExt;
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        newFilename = filename+'_'+carousel_type_mobile+'.'+fileExt;
      }
      return environment.IMAGE_PATH_URL_CONVERTED+newFilename;
    }
  }

  getSrcSet(image_name, carousel_type_mobile, carousel_type_web, type) {
    const filename = image_name.split('.').slice(0, -1).join('.');
    let fileExt;
    if(type == 'original'){
       fileExt = image_name.split('.').pop();
    } else {
       fileExt = "webp";
    }
    var mobilename = environment.IMAGE_PATH_URL_CONVERTED + filename + '_' + carousel_type_mobile + '.' + fileExt;
    var webname = environment.IMAGE_PATH_URL_CONVERTED + filename + '_' + carousel_type_web + '.' + fileExt;
    return mobilename + " " + carousel_type_mobile + "w," + webname + " " + carousel_type_web + "w";
  }

  getSetUTMValues(url){
    this.route.queryParams.subscribe(params => {
      const searchParams = new URLSearchParams();
      const utm_keys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'gclid']
      for (const key of utm_keys) {
        if (params.hasOwnProperty(key)) {
          searchParams.append(key, params[key]);
        }
      }
      const queryString = searchParams.toString();
      if(queryString.length != 0) url = url.includes('?') ? `${url}&${queryString}` : `${url}?${queryString}`;
    });
    return url;
  }

  checkWebP(callback) {
    if (isPlatformBrowser(this.platformId)) {
      var webP = new Image();
      webP.onload = webP.onerror = function () {
          callback(webP.height == 2);
      };
      webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    }
  }
}
