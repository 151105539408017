import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
// import { LottieModule } from 'ngx-lottie';
import { AppComponent } from './app.component';

import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { PublicModule } from './modules/public.module';
import { isPlatformBrowser } from '@angular/common';
import { NgUniversalModule } from '@trilon/ng-universal';

// import player from 'lottie-web/build/player/lottie_svg';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AppService } from './services/app.service';
import { LoaderService } from '../app/services/loader.service';

// export function playerFactory() {
//   return player;
// }
@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'filmbox' }),
    AppRoutingModule,
    PublicModule,
    NgUniversalModule,
    // LottieModule.forRoot({
    //   player: playerFactory,
    //   useCache: true
    // }),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) => () => appService.init(),
      deps: [AppService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    LoaderService
  ],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId)
      ? 'in the browser'
      : 'on the server';
  }
}
