export const environment = {
    production: false,
    environmentName:'sit',
    API_URL: "https://wtapi.tv2zdev.com/dev",
    SOCIAL_LOGIN: "https://wtapi.tv2zdev.com/dev/",
    version:'v2/',
    v3_version:'v3/',
    ANGULAR_SITE_URL: "https://wtdev.tv2zdev.com/",
    IMAGE_PATH_URL: "https://fblpreprodassets.tv2zcdn.com/images/",
    IMAGE_PATH_URL_CONVERTED: "https://fblpreprodassets.tv2zcdn.com/converted/images/",
    TRANSLATE_PATH_URL: "https://fblpreprodassets.tv2zcdn.com/translations/",
    BASE_URL: "https://wtdevssr.tv2zdev.com",
    COKKIE_DOMAIN: ".tv2zdev.com",
    APP_NAME: "filmbox",
    htmlAppName: 'film1',
    indexFile: 'index_filmbox.html'
  };