import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PublicRoutingModule } from './public-routing.module';
import { RouterModule } from '@angular/router';;
import { HttpClientModule } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';


@NgModule({
  declarations: [],
  imports: [
    RouterModule,
    FormsModule,
    PublicRoutingModule,
    CookieModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: []
})
export class PublicModule { }
