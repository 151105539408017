import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('../home/home.module').then(m => m.HomeModule) },
  {
    path: ':region/home',
    loadChildren: () => import('../home/home.module').then(m => m.HomeModule),
  },
  {
    path: ':region/movies',
    loadChildren: () => import('../movies/movies.module').then(m => m.MoviesModule),
  },
  {
    path: ':region/series',
    loadChildren: () => import('../series/series.module').then(m => m.SeriesModule),
  },
  {
    path: ':region/channels',
    loadChildren: () => import('../channels/channels.module').then(m => m.ChannelsModule),
  },
  {
    path:'tv-guide',
    loadChildren: () => import('../tv-guide/tv-guide.module').then(m => m.TvGuideModule),
  },
  {
    path:':region/tv-guide',
    loadChildren: () => import('../tv-guide/tv-guide.module').then(m => m.TvGuideModule),
  },
  {
    path: ':region/where-to-watch',
    loadChildren: () => import('../where-to-watch/where-to-watch.module').then(m=> m.WheretoWatchModule)
  },
  {
    path: ':region/genre',
    loadChildren: () => import('../genre-curation/genre-curation.module').then(m => m.GenreCurationModule),
  },
  {
    path: ':region/curation',
    loadChildren: () => import('../genre-curation/genre-curation.module').then(m => m.GenreCurationModule),
  },
  {
    path: ':region/genre/:slug',
    loadChildren: () => import('../genre-curation-detail/genre-curation-detail.module').then(m=> m.GenreCurationDetailModule),
  },
  {
    path: ':region/curation/:slug',
    loadChildren: () => import('../genre-curation-detail/genre-curation-detail.module').then(m=> m.GenreCurationDetailModule),
  },
  {
    path: ':region/series/:slug',
    loadChildren: () => import('../seriesdetails/seriesdetails.module').then(m => m.SeriesDetailsModule),
  },
  {
    path: ':region/movies/:slug',
    loadChildren: () => import('../moviesdetails/moviesdetails.module').then(m => m.MoviesDetailsModule),
  },
  {
    path: ':region/live/:slug',
    loadChildren: () => import('../channelsdetails/channelsdetails.module').then(m => m.ChannelsDetailsModule),
  },
  {
    path: ':region/director/:slug',
    loadChildren: () => import('../cast/cast.module').then(m => m.CastModule),
  },
  {
    path: ':region/actor/:slug',
    loadChildren: () => import('../cast/cast.module').then(m => m.CastModule),
  },
  {
    path: ':region/lists/:slug',
    loadChildren: () => import('../collectionlist/collectionlist.module').then(m => m.CollectionListModule),
  },
  {
    path: ':region/watch-free',
    loadChildren: () => import('../watch-free/watchfree.module').then(m => m.WatchfreeModule),
  },
  {
    path: ':region/operator',
    loadChildren: () => import('../operator-mainpage/operator.module').then(m => m.OperatorModule),
  },
  {
    path: ':region/operator-signup/:id',
    loadChildren: () => import('../operator-signup/operator-signup.module').then(m => m.OperatorSignupModule),
  },
  {
    path: ':region/pages/:type',
    loadChildren: () => import('../static/static.module').then(m => m.StaticModule),
  },
  {
    path: ':region/feedback',
    loadChildren: () => import('../feedback/feedback.module').then(m => m.FeedbackModule),
  },
  {
    path: 'not-available',
    loadChildren: () => import('../not-availble/not-availble.module').then(m=> m.NotAvailbleModule)
  },
  {
    path: ':region/not-available',
    loadChildren: () => import('../not-availble/not-availble.module').then(m=> m.NotAvailbleModule)
  },
  {
    path: ':region/:slug',
    loadChildren: () => import('../movies/movies.module').then(m => m.MoviesModule),
  },
  {
    path: '**',
    loadChildren: () => import('../not-found/not-found.module').then(m => m.NotFoundModule),
  },
  // { path: ':region/movies', component: MoviesComponent },
  // { path: ':region/series', component: SeriesComponent },
  // { path: ':region/channels', component: ChannelsComponent },
  // { path: ':slug', component: ContentDetailComponent },
  // { path: ':region/series/:slug', component: SeriesdetailsComponent },
  // { path: ':region/movies/:slug', component: MoviesdetailsComponent },
  // { path: ':region/live/:slug', component: ChannelsdetailsComponent },
  // { path: ':region/director/:slug', component: CastComponent },
  // { path: ':region/actor/:slug', component: CastComponent },
  // { path: ':region/lists/:slug', component: CollectionlistComponent },
  // { path: ':region/watch-free', component: WatchFreeComponent },
  // { path: ':region/operator', component: OperatorMainpageComponent },
  // { path: ':region/operator-signup/:id', component: OperatorSignupComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }

