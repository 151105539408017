import { Injectable, Inject, PLATFORM_ID, APP_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isPlatformBrowser, KeyValue } from '@angular/common';
import { environment } from 'src/environments/environment'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId: object,
              @Inject(APP_ID) private appId: string) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          console.error('LOGOUT');
          if(isPlatformBrowser(this.platformId)) {
            window.location.href = environment.BASE_URL;
          }
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
